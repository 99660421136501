import { createApp } from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import Dashboard from "../components/Dashboard.vue";

import "@/css/bootstrap-grid.min.css"
import "@/css/app.css"

const app = createApp(App)
app.use(vueCustomElement)
app.component('vue-widget', App)
app.component('dashboard', Dashboard)
app.mount('#east_widgets')