<script setup>
  import { emitter } from "@/emitter";
  import {onMounted, ref, defineProps} from "vue";

  const props = defineProps({
    widget: Object,
  })


  const widgetData = ref();
  const widgetTitle = ref();
  const loading = ref(true);
  const tagsList = ref([]);
  const fetchData = (tags = null) => {
    if(tags !== null) {
      tagsList.value = tags
    }
    loading.value = true
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({"tags": tags, "type": props.widget.type, "uid": props.widget.uuid, "iterable": true})
    };
    fetch(`${process.env.VUE_APP_API_BASE_URL}/widget/data/`, requestOptions).then(async response => {
      if(response.ok) {
        const jsonResponse = await response.json(); // Parse the JSON response
        console.log(jsonResponse)
        widgetTitle.value = jsonResponse.title
        widgetData.value = jsonResponse.data
        loading.value = false
      }
    }).catch(error => {
      console.log(error)
    })
  }

  onMounted(() => {
    fetchData()
    emitter.on("tagsChanged", (tags) => {
      fetchData(tags)
    })
  })

</script>

<template>
  <div class="widget" v-if="!loading">
    <table class="widget_structure" style="background: none;">
      <tr>
        <td class="t_heading" style="background: none;">
          <h1 class="we-h1">{{ widgetData.count }}</h1>
        </td>
      </tr>
      <tr>
        <td class="t_body" style="background: none;">
          <p class="we-p" v-if="tagsList.length">{{ widgetTitle }} <strong>{{ tagsList.join(", ") }}</strong></p>
          <p v-else class="we-p">{{widgetTitle }}</p>
        </td>
      </tr>
      <tr><td class="t_tag">&nbsp;</td></tr>
    </table>
  </div>
  <div v-else>
    <img src='data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_b2T7{animation:spinner_xe7Q .8s linear infinite}.spinner_YRVV{animation-delay:-.65s}.spinner_c9oY{animation-delay:-.5s}@keyframes spinner_xe7Q{93.75%,100%{r:3px}46.875%{r:.2px}}</style><circle class="spinner_b2T7" cx="4" cy="12" r="3"/><circle class="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3"/><circle class="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3"/></svg>' />
  </div>
</template>

<style scoped>
  h1{
    font-weight: 600;
  }
  p{
    line-height: 1.5;
  }
</style>
