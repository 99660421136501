<script setup>
  import { LineChart} from 'vue-chart-3';
  import { Chart, registerables } from "chart.js";
  import { emitter } from "@/emitter";
  import {onMounted, ref, defineProps, computed} from "vue";

  const widgetData = ref();
  const loading = ref(true);
  const tagsList = ref([]);

  const props = defineProps({
    widget: Object,
  })

  Chart.register(...registerables);

  const lineChartRef = ref()
  const data = ref([])
  const labels = ref([])
  const chartOptions = ref({
    plugins: {
      legend: {
        display: false
      }
    }
  })



  const graphData = computed(() => {
    return {
      labels: labels.value,
      datasets: [
        {
          data: data.value,
          backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'],
        },
      ],
    }
  })

  const fetchData = (tags = null) => {
    if(tags !== null) {
      tagsList.value = tags
    }
    loading.value = true
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({"tags": tags, "type": props.widget.type, "uid": props.widget.uuid, "iterable": false})
    };
    fetch(`${process.env.VUE_APP_API_BASE_URL}/widget/data/`, requestOptions).then(async response => {
      if(response.ok) {
        loading.value = false
        widgetData.value = await response.json()
        labels.value = []
        data.value = []
        widgetData.value.forEach(val => {
          labels.value.push(val.literal)
          data.value.push(val.result)
        })
        // lineChartRef.value.update()
      }
    }).catch(error => {
      console.log(error)
    })
  }

  onMounted(() => {
    fetchData()
    emitter.on("tagsChanged", (tags) => {
      fetchData(tags)
    })
  })

</script>

<template>
  <div class="widget" v-if="!loading">
    <LineChart
        v-if="widgetData"
        ref="lineChartRef"
        :height="200"
        :chartData="graphData"
        :options="chartOptions"
    />
  </div>
  <div v-else>
    <img src='data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_b2T7{animation:spinner_xe7Q .8s linear infinite}.spinner_YRVV{animation-delay:-.65s}.spinner_c9oY{animation-delay:-.5s}@keyframes spinner_xe7Q{93.75%,100%{r:3px}46.875%{r:.2px}}</style><circle class="spinner_b2T7" cx="4" cy="12" r="3"/><circle class="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3"/><circle class="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3"/></svg>' />
  </div>
</template>

<style scoped>

</style>