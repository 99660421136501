<script setup>
import {defineProps, ref} from "vue";
import {emitter} from "@/emitter";
  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
  })
  const formData = ref("");
  const handleChange = () => {
    emitter.emit("searchChanged", {"field": props.data.search_key, "value": formData.value});
  }
</script>

<template>
  <div class="field">
    <label v-if="props.data.title">{{ props.data.title }}</label>
    <select v-model="formData" @change="handleChange" :name="props.data.search_key">
      <option v-for="(key, option) in props.data.value" :key="key" :value="option">{{ key }}</option>
    </select>
  </div>
</template>

<style scoped>

</style>