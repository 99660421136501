<script setup>
import {Chart, registerables} from "chart.js";
import {ArcSlice, extractSets, VennDiagramChart, VennDiagramController} from "chartjs-chart-venn";
import {defineProps, onMounted, ref} from "vue";
import {defineChartComponent} from "vue-chart-3";
import {emitter} from "@/emitter";
import NumberWidget from "./NumberWidget.vue";

Chart.register(VennDiagramChart, VennDiagramController, ArcSlice, ...registerables);

const props = defineProps({
  widget: Object,
})


const widgetData = ref();
const widgetTitle = ref();
const loading = ref(true);
const tagsList = ref([]);

defineChartComponent('VennChart', 'venn');

// eslint-disable-next-line no-unused-vars
let chart = null;

const data = [
  {label: 'React', values: ['alex', 'casey', 'drew', 'hunter']},
  {label: 'Node.js', values: ['casey', 'drew', 'jade']},
  {label: 'Azure', values: ['drew', 'glen', 'jade']},
]

let venn_data = extractSets(
    data,
);

const fetchData = (tags = null) => {
  if (tags !== null) {
    tagsList.value = tags
  }
  loading.value = true

  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify({"tags": tags, "type": props.widget.type, "uid": props.widget.uuid, "iterable": 0})
  };
  fetch(`${process.env.VUE_APP_API_BASE_URL}/widget/data/`, requestOptions).then(async response => {
    if (response.ok) {
      let res = await response.json()
      widgetData.value = res.data
      widgetTitle.value = res.title
      const res_formatted = []

      for (const [key, value] of Object.entries(res.data)) {
        res_formatted.push({"label": key, "values": value})
      }
      venn_data = extractSets(
          res_formatted,
      )
      destroyChart()
      createChart()
      loading.value = false
    }
  }).catch(error => {
    console.log(error)
  })
}
const createChart = () => {
  const ctx = document.getElementById("cvs")

  chart = new Chart(ctx, {
    type: "venn",
    data: venn_data,
    responsive: true,
    options: {
      backgroundColor: ["#BF202F", "#24283C", "#4F5A90", '#E93F4F', '#515672', '#79B1E0', '#CCCCCC'],
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.raw.label + ": " + context.raw.values.length;
            }
          }
        },
        legend: {
          display: false
        },
      },
      scales: {
        x: {
          ticks: {
            position: 'bottom',
            color: 'white',
            font: {
              size: 10,
            }
          }
        },
        y: {
          ticks: {
            position: 'left',
            color: 'white',
            font: {
              size: 16,
            }
          }
        }
      },
      title: {
        display: false,
        text: "Chart.js Venn Diagram Chart"
      }
    }
  });
}

const destroyChart = () => {
  chart.destroy()
}

onMounted(() => {
  fetchData()
  emitter.on("tagsChanged", (tags) => {
    destroyChart()
    fetchData(tags)
  })
  createChart()
})
</script>

<!-- If we have only one value, let's use the NumberWidget instead of Venn -->


<template>
  <div class="widget" v-show="tagsList.length">
    <canvas id="cvs"></canvas>
  </div>
  <div style="width:100%" v-show="!tagsList.length">
    <NumberWidget :widget="widget"/>
  </div>
</template>

<style scoped>

</style>
