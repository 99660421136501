<script setup>
import {defineProps, ref} from "vue";
  import {emitter} from "@/emitter";
  const props = defineProps({
    data: {
      type: Object,
      required: true
    },
  })
  const formData = ref("");
  const handleChange = () => {
    emitter.emit("searchChanged", {"field": props.data.search_key, "value": formData.value});
  }
</script>

<template>
  <div class="field">
    <label v-if="props.data.title">{{ props.data.title }}</label>
    <input v-model="formData" @change="handleChange" type="text" :placeholder="props.data.placeholder" :name="props.data.search_key" />
  </div>
</template>

<style scoped>

</style>