<script setup>
  import { emitter } from "@/emitter";
  import {onMounted, ref, defineProps, computed} from "vue";

  const widgetData = ref();
  const widgetTitle = ref();
  const loading = ref(true);
  const tagsList = ref([]);

  const props = defineProps({
    widget: Object,
  })

  const change = computed(() => {
    if (widgetData.value.previous > widgetData.value.current) {
      return "by " + ((widgetData.value.current - widgetData.value.previous) / widgetData.value.previous * 100).toFixed(2) + "%"
    }
    if (widgetData.value.previous < widgetData.value.current) {
      return "by " + ((widgetData.value.current - widgetData.value.previous) / widgetData.value.current * 100).toFixed(2) + "%"
    }
    return "No changes"
  })

  const fetchData = (tags = null) => {
    if(tags !== null) {
      tagsList.value = tags
    }
    loading.value = true
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({"tags": tags, "type": props.widget.type, "uid": props.widget.uuid, "iterable": true})
    };
    fetch(`${process.env.VUE_APP_API_BASE_URL}/widget/data/`, requestOptions).then(async response => {
      if(response.ok) {
        const jsonResponse = await response.json(); // Parse the JSON response
        console.log(jsonResponse)
        widgetTitle.value = jsonResponse.title
        widgetData.value = jsonResponse.data

        loading.value = false
      }
    }).catch(error => {
      console.log(error)
    })
  }

  onMounted(() => {
    fetchData()
    emitter.on("tagsChanged", (tags) => {
      fetchData(tags)
    })
  })
</script>

<template>
  <div class="widget" v-if="!loading && widgetData">
    <p>Trend</p>
    <img class="indicator" :class="{'down': widgetData.previous > widgetData.current, 'up': widgetData.previous < widgetData.current, 'equal': widgetData.previous === widgetData.current}" src='data:image/svg+xml;utf8,<svg width="15" height="15" viewBox="0 0 15 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50001 3.79291L10.2071 6.50001L9.50001 7.20712L8.00001 5.70712V11H7.00001V5.70712L5.50001 7.20712L4.7929 6.50001L7.50001 3.79291Z" fill="currentColor"/> </svg>' />
    <p>{{ change }}</p>
    <p>{{ widgetTitle }}</p>
  </div>
  <div v-else>
    <img src='data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_b2T7{animation:spinner_xe7Q .8s linear infinite}.spinner_YRVV{animation-delay:-.65s}.spinner_c9oY{animation-delay:-.5s}@keyframes spinner_xe7Q{93.75%,100%{r:3px}46.875%{r:.2px}}</style><circle class="spinner_b2T7" cx="4" cy="12" r="3"/><circle class="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3"/><circle class="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3"/></svg>' />
  </div>
</template>

<style scoped>
  .indicator{
    width: 128px;
    height: 128px;
  }
  .down{
    transform: rotate(180deg);
  }
</style>
