<script setup>
  import { emitter } from "@/emitter";
  import {onMounted, ref, defineProps} from "vue";

  const props = defineProps({
    widget: Object,
  })


  const widgetData = ref();
  const loading = ref(true);
  const tagsList = ref([]);
  const fetchData = (tags = null) => {
    if(tags !== null) {
      tagsList.value = tags
    }
    loading.value = true
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({"tags": tags, "type": props.widget.type, "uid": props.widget.uuid, "iterable": false})
    };
    fetch(`${process.env.VUE_APP_API_BASE_URL}/widget/data/`, requestOptions).then(async response => {
      if(response.ok) {
        widgetData.value = await response.json()
        loading.value = false
      }
    }).catch(error => {
      console.log(error)
    })
  }

  onMounted(() => {
    fetchData()
    emitter.on("tagsChanged", (tags) => {
      fetchData(tags)
    })
  })

</script>

<template>
  <div class="widget" v-if="!loading">
    <table class="widget_structure">
      <tr>
        <td class="t_heading">
          <a :href="widgetData.file_link">
            <img src='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 499.93"><path fill-rule="nonzero" d="M114.51 278.73c-4.37-4.2-4.55-11.2-.38-15.62a10.862 10.862 0 0 1 15.46-.39l115.34 111.34V11.07C244.93 4.95 249.88 0 256 0c6.11 0 11.06 4.95 11.06 11.07v362.42L378.1 262.85c4.3-4.27 11.23-4.21 15.46.13 4.23 4.35 4.17 11.35-.13 15.62L264.71 406.85a11.015 11.015 0 0 1-8.71 4.25c-3.45 0-6.52-1.57-8.56-4.04L114.51 278.73zm375.35 110.71c0-6.11 4.96-11.07 11.07-11.07S512 383.33 512 389.44v99.42c0 6.12-4.96 11.07-11.07 11.07H11.07C4.95 499.93 0 494.98 0 488.86v-99.42c0-6.11 4.95-11.07 11.07-11.07 6.11 0 11.07 4.96 11.07 11.07v88.36h467.72v-88.36z"/></svg>' />
          </a>
        </td>
      </tr>
      <tr>
        <td class="t_body">
          <p>
            <a :href="widgetData.file_link">Download file attached to the widget</a>
          </p>
        </td>
      </tr>
      <tr><td class="t_tag">&nbsp;</td></tr>
    </table>
  </div>
  <div v-else>
    <img src='data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_b2T7{animation:spinner_xe7Q .8s linear infinite}.spinner_YRVV{animation-delay:-.65s}.spinner_c9oY{animation-delay:-.5s}@keyframes spinner_xe7Q{93.75%,100%{r:3px}46.875%{r:.2px}}</style><circle class="spinner_b2T7" cx="4" cy="12" r="3"/><circle class="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3"/><circle class="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3"/></svg>' />
  </div>
</template>

<style scoped>
  .widget{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  a{
    color: #000;
    text-decoration: none;
  }
  a img {
    width: 32px;
    height: 32px;
    margin: 16px 0px;
  }
  h1{
    font-weight: 600;
  }
  p{
    line-height: 1.5;
  }
</style>