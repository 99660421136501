<script setup>
import {computed, onMounted, ref} from "vue";
import {emitter} from "@/emitter";

import Multiselect from '@vueform/multiselect'

import "@vueform/multiselect/themes/default.css"
import TextField from "./fields/TextField.vue";

import NumberField from "./fields/NumberField.vue";
import SelectField from "./fields/SelectField.vue";
import CheckboxField from "./fields/CheckboxField.vue";

import LockedWidget from "./widgets/LockedWidget.vue";
import NumberWidget from "./widgets/NumberWidget.vue";
import FileWidget from "./widgets/FileWidget.vue";
import VennDiagramWidget from "./widgets/VennDiagramWidget.vue";
import ExternalApiWidget from "./widgets/ExternalApiWidget.vue";
import LineChartWidget from "./widgets/LineChartWidget.vue";
import TrendWidget from "./widgets/TrendWidget.vue";

const tagList = ref([]);

const tags = ref([]);
const dashboard = ref();

const widgetMap = {
  "candidates_tech": NumberWidget,
  "locked": LockedWidget,
  "candidates_tech_seniority": NumberWidget,
  "job_ads_tech": NumberWidget,
  "accounts_tech": NumberWidget,
  "download_file": FileWidget,
  "job_ads_line_chart_date_range": LineChartWidget,
  "job_ads_trend": TrendWidget,
  "candidates_tech_venn_chart": VennDiagramWidget,
  "external_api": ExternalApiWidget
}

const filterMap = {
  "text": TextField,
  "number": NumberField,
  "select": SelectField,
  "checkbox": CheckboxField
}

const script = document.currentScript;
const level = computed(() => {
  return script.dataset.level || "2";
})
const uid = computed(() => {
  return script.dataset.uid || "d258e703-d087-45bb-a358-125a2c156172";
})
const handleChangeTag = (tags) => {
  if (tags.length < 4) {
    tags.value = tags;
  }
  emitter.emit("tagsChanged", tags.value);
}

fetch(`${process.env.VUE_APP_API_BASE_URL}/dashboard/${uid.value}/`).then(async response => {
  if (response.ok) {
    dashboard.value = await response.json()
  }
}).catch(error => {
  console.log(error)
})

fetch(`${process.env.VUE_APP_API_BASE_URL}/resources/tags/`).then(async response => {
  if (response.ok) {
    const res = await response.json()
    res.forEach(tag => {
      tagList.value.push({"value": tag.name, "label": tag.name})
    })
  }
}).catch(error => {
  console.log(error)
})

const fetchLanguages = async (query) => {
  return tagList.value.filter(tag => tag.label.toLowerCase().includes(query.toLowerCase()))
}

onMounted(() => {
  emitter.on("searchChanged", (data) => {
    console.log(data)
  })
})

</script>

<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="container we-container" style="background: none;">
    <div class="row" v-if="dashboard">
      <div class="col-12">
        <div class="search" v-if="dashboard.search_included">
          <div class="search-prepend">
            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                  stroke="#7C7E8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21 21L15 15" stroke="#7C7E8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <Multiselect
              class="multiselect"
              v-model="tags"
              :options="async function(query) {
              return await fetchLanguages(query) // check JS block for implementation
            }"
              mode="tags"
              placeholder="Search by technology (maximum 3)"
              :close-on-select="true"
              :filter-results="false"
              :min-chars="1"
              :resolve-on-load="false"
              :delay="0"
              :max="3"
              :searchable="true"
              @change="handleChangeTag"
          />
        </div>
        <div class="extra_filters">
          <div class="extra_filters_group" v-if="dashboard.filters.length">
            <div class="filter_field" v-for="(filter, key) in dashboard.filters" :key="key">
              <component :is="filterMap[filter.type]" :data="filter"/>
            </div>
          </div>
        </div>
      </div>
      <div class="widget_groups container-fluid" v-if="dashboard?.widget_groups">
        <div v-for="(wg, key) in dashboard.widget_groups" :key="key">
          <div class="widget_group" v-if="wg.is_active">
              <div class="widget_group__widgets grid-container">

              <div v-for="(widget, key) in wg.widgets" :key="key"
                :class="['widget_group__widget', widget.type === 'candidates_tech_venn_chart' ? 'venn-widget' : '']">

                <component :is="widgetMap[widget.type]" :tags="tags" v-if="level >= widget.user_level"
                           :widget="widget"/>
                <locked-widget v-else/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      Dashboard with uid {{ uid.value }} not found
    </div>
  </div>
</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');


.grid-container {
  display: grid;
  /* Auto-fit columns with a minimum width of 0 to ensure 4 columns on big screens */
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 1rem;
}

.venn-widget {
  grid-column: span 2; // spans 2 columns
}

.search-prepend {
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 10;
}

@media (max-width: 768px) {
  .grid-container {
    /* One column on smaller screens */
    grid-template-columns: repeat(1, 1fr);
  }

  .search-prepend svg {
    width: 25px!important;
    height: 25px!important;
  }

  .venn-widget {
    /* Ensure the Venn widget takes the full row on small screens */
    grid-column: span 1;
  }
}

.widget_set {
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.widget_set__widget {
  text-align: center;
}

.search {
  margin-bottom: 2rem;

  &_input {
    border: none;
    border-bottom: 1px solid #CECECE;
    border-radius: 0;
    font-family: "Montserrat", sans-serif;
  }
}

.v3ti-content::before {

}

.widget_group__widget {

}

</style>
